<template>
  <div class="skeleton-inner">
    <slot />
  </div>
</template>

<style lang="scss">
  .skeleton-inner {
    @apply rounded-md bg-dark-300 dark:bg-dark-700;
  }
</style>
